// src/components/Header.js
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export default function Footer() {
  return (
    <>
      <Container>
        <hr />
        <Row className="text-muted">
          <Col>
            <p>info@aavadhi.ai</p>
          </Col>
          <Col className="col-auto">
            <p>@2025 Aavadhi.ai</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
